<template>
  <div class="loginPage" v-loading="loading" :element-loading-text="loadTxt">
    <el-alert v-if="alert" :title="alertTes" type="error" />
    <div
      v-show="xxshow"
      style="font-size:16px;display:;margin:auto;overflow: scroll;"
    >
      <p>icode：{{ icode }}</p>
      <p class="Mty">{{ igdt }}</p>
      <p class="Mty">res：{{ ires }}</p>
      <p class="Mty">token=：{{ itoken }}</p>
      <p class="Mty">info=：{{ iuserinfo }}</p>
    </div>
    <div style="margin:40px;" @click="zhgotourl">去首页看看</div>
    <div class="cksj" @click="showxs">..</div>
    <div v-on:dblclick="doubleclick" class="gbjl">.</div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getuserid, getuserinfo } from '@/api/wechat'
import { sign, setItem, getItem } from '@/utils/storage'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'
const router = useRoute()
const store = useStore()
const icode = router.query.code

const loading = ref(false)
const loadTxt = ref('正在等待腾讯响应')

const utoken = ref(store.getters.token === '' ? '' : store.getters.token)

const xxshow = ref(false)
const ires = ref('')
const itoken = ref('')
const iuserinfo = ref('')
const igdt = ref('')

const alert = ref(false)
const alertTes = ref('')

const userId = ref('')
const guserid = async () => {
  loading.value = true
  loadTxt.value = '正在向腾讯获取用户标识'
  var gurl = await getuserid(
    sign({ code: icode, tg: 'ok', sjs: Math.ceil(Math.random() * 10) })
  )
  loading.value = false
  igdt.value = JSON.stringify(gurl)
  if (gurl.errcode === 0) {
    // 下一步
    var iusid = ''
    var zt = 0
    if (gurl.user_ticket) {
      // 当用户为企业成员时 显示 user_ticket
      iusid = gurl.user_ticket
      zt = 1
    } else {
      // 非企业成员时，返回示 external_userid
      iusid = gurl.external_userid
      zt = 2
    }
    getUserInfo(gurl.UserId, gurl.DeviceId, iusid, zt, gurl)
  } else {
    alert.value = true
    alertTes.value = gurl.errmsg
    ElMessage.error(gurl.errmsg)
  }
  console.log('获取---', gurl, userId.value, setItem, getItem)
}
guserid()

const getUserInfo = async (usid, devid, iusid, zt, gurl) => {
  loading.value = true
  loadTxt.value = '正在向腾讯请求用户信息，请耐心等待'
  ires.value =
    '请求-userId: ' +
    usid +
    ',devid:' +
    devid +
    ',iusid:' +
    iusid +
    ',zt:' +
    zt +
    '---<br />----' +
    JSON.stringify(gurl) +
    '------'
  // var res = await getuserinfo(
  //   sign({
  //     userId: usid,
  //     devid: devid,
  //     tg: 'ok',
  //     sjs: Math.ceil(Math.random() * 10)
  //   })
  // )
  var res = await getuserinfo({
    userId: usid,
    devid: devid,
    iusid: iusid,
    zt: zt,
    tg: 'ok'
  })
  loading.value = false
  igdt.value = JSON.stringify(res)

  ires.value = '90--' + JSON.stringify(res.token)

  alert.value = true

  setItem('token', JSON.stringify(res.token))
  itoken.value = getItem('token')

  utoken.value = res.token

  setItem('userinfo', JSON.stringify(res.userinfo))
  iuserinfo.value = getItem('userinfo')

  window.location.href = '/'

  ires.value = 'info：' + JSON.stringify(res.userinfo)

  // alertTes.value = gurl.errmsg
}

const doubleclick = () => {
  loading.value = false
}

const zhgotourl = () => {
  window.location.href = '/'
}

// import { useRouter } from 'vue-router'
// const router = useRouter()
// const moblieLogin = () => {
//   router.push('/')
// }

const showxs = () => {
  xxshow.value = !xxshow.value
}
</script>
<style lang="scss" scoped>
.cksj,
.gbjl {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 0;
  bottom: 0;
  color: #ccc;

  z-index: 188;
}
.cksj {
  text-align: left;
  left: 0;
}
.gbjl {
  text-align: right;
  right: 0;
}
.loginPage {
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  .btnGroup {
  }
}
.Mty {
  margin-top: 18px;
}
</style>
