<template>
  <div class="loginPage">
    <div class="btnGroup" style="display:none;">
      <div>
        <el-button class="btn" @click="wechatLogin" type="primary"
          >企业微信登录</el-button
        >
      </div>
      <div>
        <el-button class="btn" @click="moblieLogin" type="success"
          >手机号登录</el-button
        >
      </div>
    </div>
  </div>
</template>
<script setup>
// import { ref } from 'vue'
import { sign } from '@/utils/storage'
import { oauth2url } from '@/api/wechat'
// import { useRouter } from 'vue-router'

const wechatLogin = async () => {
  // console.log('企业微信登录')
  var gurl = await oauth2url(sign({ sjs: Math.ceil(Math.random() * 10) }))

  window.location.href = gurl
}
wechatLogin()
// const router = useRouter()
const moblieLogin = () => {
  // console.log('手机号登录')
  router.push('/')
  // window.location.href = 'wxuser?code=aaaaa&bbb=ss'
}
</script>
<style lang="scss" scoped>
.loginPage {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .btnGroup {
    width: 80%;
    text-align: center;
    div:last-child {
      margin-top: 10%;
    }
    .btn {
      width: 100%;
      max-width: 400px;
    }
  }
}
</style>
