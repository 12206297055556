import request from '@/utils/request'

/**
 * 构造网页授权链接
 */
export const oauth2url = params => {
  return request({
    url: '/api/oauth2url',
    method: 'get',
    params
  })
}

/**
 * 获取用户ID
 */
export const getuserid = params => {
  return request({
    url: '/api/getuserid',
    method: 'get',
    params
  })
}

/**
 * 获取用户ID
 */
export const getuserinfo = params => {
  return request({
    url: '/api/getuserinfo',
    method: 'get',
    params
  })
}
